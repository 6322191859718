import React, { useEffect, useRef, useState } from "react";
import { Fragment } from "react";
import { Listbox } from "@headlessui/react";
import { AiOutlineCheck } from "react-icons/ai";
import { FiChevronDown } from "react-icons/fi";
import { Transition } from "@headlessui/react";
import RecommendedSlots from "./recommended_slots/RecommendedSlots";
import AvailableSlotsComponent from "./AvailableSlotsComponent";
import { useDispatch, useSelector } from "react-redux";
import { getAllCustomerProducts } from "../../../store/actions";
import { sortByGropoolDate, sortByRemainingSlot } from "../../../core/helpers";
import { authService } from "../../../services/authService";
import Container from "../my_container/Container";
import GropoolEmptyImage from "../../../assets/rewardBankHistoryEmptyImage.svg";
import { useNavigate } from "react-router-dom";

const sortLists = [
  { name: "GroPool Date Ascending" },
  { name: "GroPool Date Descending" },
  { name: "Slot remaining Ascending" },
  { name: "Slot remaining Descending" },
];

const GropoolOrders = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const gropoolRef = useRef();
  const [selectedLisbox, setSelectedLisbox] = useState(sortLists[0]?.name);
  const [gropoolProducts, setGropoolProducts] = useState([]);
  const [isSeeAll, setIsSeeAll] = useState(false);
  const [fetchedRecommendedPrds, setFetchedRecommendedPrds] = useState([]);
  const customerId = authService.getCurrentUserCustomerId();

  const { allCustomerProducts } = useSelector((state) => state.productShare);

  useEffect(() => {
    if (!allCustomerProducts?.product_sharing_info?.length) {
      handleProductSharingAll();
    }
  }, [allCustomerProducts?.product_sharing_info]);

  useEffect(() => {
    if (allCustomerProducts?.recommended_product_sharing?.length) {
      setFetchedRecommendedPrds(
        allCustomerProducts?.recommended_product_sharing
      );
    }
  }, [allCustomerProducts]);

  useEffect(() => {
    if (allCustomerProducts?.product_sharing_info && selectedLisbox) {
      switch (selectedLisbox) {
        case "GroPool Date Ascending":
          const ascendingProductDate = sortByGropoolDate(
            allCustomerProducts?.product_sharing_info,
            "asc"
          );

          setGropoolProducts(ascendingProductDate);
          break;
        case "GroPool Date Descending":
          const descendingProductDate = sortByGropoolDate(
            allCustomerProducts?.product_sharing_info,
            "desc"
          );

          setGropoolProducts(descendingProductDate);
          break;
        case "Slot remaining Ascending":
          const ascendingRemainingSlot = sortByRemainingSlot(
            allCustomerProducts?.product_sharing_info,
            "asc"
          );

          setGropoolProducts(ascendingRemainingSlot);
          break;
        case "Slot remaining Descending":
          const descendingRemainingSlot = sortByRemainingSlot(
            allCustomerProducts?.product_sharing_info,
            "desc"
          );

          setGropoolProducts(descendingRemainingSlot);
          break;
        default:
          return setGropoolProducts(allCustomerProducts?.product_sharing_info);
      }
    }
  }, [allCustomerProducts?.product_sharing_info, selectedLisbox]);

  const handleProductSharingAll = async () => {
    try {
      dispatch(getAllCustomerProducts(customerId));
    } catch (err) {
      return err;
    }
  };

  return (
    <>
      {!gropoolProducts?.length ? (
        <>
          <Container className="mb-14 pt-6 @container">
            <div className="mt-8 flex flex-col items-center justify-center">
              <img src={GropoolEmptyImage} alt="img" className="h-44 w-auto" />
              <h2 className="mt-4 text-xl font-bold text-gravel-600">
                No GroPool Orders Yet
              </h2>
              <p className="mt-2 text-sm font-medium text-gray-500">
                "Oops, it seems like your GroPool orders are empty.
              </p>
            </div>
            {/* <div className="mx-auto mt-8 max-w-xs">
              <button
                onClick={() => navigate("/gropool")}
                className="w-full transform cursor-pointer rounded-md bg-olivine-500 px-10 py-4 text-xs font-semibold text-white transition-all duration-200 hover:bg-olivine-500/80 focus:outline-none active:bg-olivine-600 xsm:text-sm"
              >
                Go to GroPool Page
              </button>
            </div> */}
          </Container>
        </>
      ) : (
        <div>
          <div className="mt-8 flex flex-col sm:flex-row sm:items-center sm:justify-between">
            <div className="text-start text-xl font-semibold text-gray-700">
              GroPool Orders
            </div>
          </div>
          <div
            className={`mt-4 flex items-center ${
              gropoolProducts && gropoolProducts?.length > 8
                ? "justify-between"
                : "justify-end"
            } `}
          >
            {gropoolProducts && gropoolProducts?.length > 8 ? (
              <div className="mt-4">
                <div
                  className="group cursor-pointer font-semibold text-olivine-500"
                  onClick={() => setIsSeeAll(!isSeeAll)}
                >
                  <div> {isSeeAll ? "See Less" : "See All"} </div>
                  <hr
                    // style={{ backgroundColor: "#73964C" }}
                    className="-mt-1 h-[2px] bg-olivine-500 opacity-0 group-hover:opacity-100"
                  />
                </div>
              </div>
            ) : null}

            <Listbox value={selectedLisbox} onChange={setSelectedLisbox}>
              <div className="relative mt-5 flex items-center justify-end sm:mt-0">
                <div className="flex items-center space-x-1 xsm:space-x-2">
                  <div className="block truncate font-semibold text-gray-600">
                    {selectedLisbox}
                  </div>
                  <Listbox.Button className="relative focus:outline-none">
                    <div
                      title="sort by"
                      className="cursor-pointer rounded-full p-1 hover:bg-gray-100 active:bg-gray-200"
                    >
                      <FiChevronDown
                        className="h-6 w-6 text-olivine-500"
                        aria-hidden="true"
                      />
                    </div>
                  </Listbox.Button>
                </div>
                <Transition
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute right-0 z-50 mt-1 max-h-64 w-60 overflow-auto rounded-lg bg-white py-1 text-base shadow-xl ring-1 ring-black ring-opacity-5 focus:outline-none xsm:w-64 sm:text-sm">
                    {sortLists.map((sortList, sortListIdx) => (
                      <Listbox.Option
                        key={sortListIdx}
                        className={({ active }) =>
                          `relative cursor-default select-none py-3 pl-10 pr-4 ${
                            active
                              ? "bg-[#F5F5F5] font-semibold text-neutral-500"
                              : "font-semibold text-gray-700"
                          }`
                        }
                        value={sortList.name}
                      >
                        {({ selected }) => (
                          <>
                            <span
                              className={`block truncate ${
                                selectedLisbox ? "font-medium" : "font-normal"
                              }`}
                            >
                              {sortList.name}
                            </span>
                            {selected ? (
                              <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-olivine-500">
                                <AiOutlineCheck
                                  className="h-5 w-5"
                                  aria-hidden="true"
                                />
                              </span>
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            </Listbox>
          </div>
          <div className="mt-5 grid gap-4 grid-auto-fit-md">
            {/* Cards are coming here */}
            <div ref={gropoolRef} className="mt-10 grid gap-4 grid-auto-fit-md">
              {gropoolProducts && gropoolProducts?.length
                ? isSeeAll
                  ? gropoolProducts.map((availableSlotList, index) => {
                      return (
                        <AvailableSlotsComponent
                          key={index}
                          category={availableSlotList.group_name}
                          name={availableSlotList.item_name}
                          image={availableSlotList.item_image_url}
                          ref_no={availableSlotList.ref_no}
                          slotPrice={availableSlotList.slot_price}
                          slotDate={availableSlotList.created_date}
                          slot_number={availableSlotList.slot_number}
                          total_purchased_slot={
                            availableSlotList.total_purchased_slot
                          }
                          shareLink={availableSlotList?.web_share_link}
                          group_name={availableSlotList?.sharing_name}
                        />
                      );
                    })
                  : gropoolProducts
                      .slice(0, 8)
                      .map((availableSlotList, index) => {
                        return (
                          <AvailableSlotsComponent
                            key={index}
                            category={availableSlotList.group_name}
                            name={availableSlotList.item_name}
                            image={availableSlotList.item_image_url}
                            ref_no={availableSlotList.ref_no}
                            slotPrice={availableSlotList.slot_price}
                            slotDate={availableSlotList.created_date}
                            slot_number={availableSlotList.slot_number}
                            total_purchased_slot={
                              availableSlotList.total_purchased_slot
                            }
                            shareLink={availableSlotList?.web_share_link}
                            group_name={availableSlotList?.sharing_name}
                          />
                        );
                      })
                : null}
            </div>
          </div>
          <RecommendedSlots prods={fetchedRecommendedPrds} />
        </div>
      )}
    </>
  );
};

export default GropoolOrders;
