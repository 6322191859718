import React from "react";
import { BiArrowBack } from "react-icons/bi";
import ProgressBar from "@ramonak/react-progress-bar";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import { CurrencyComponent } from "../../../core/helpers";
import toast from "react-hot-toast";

const AvailableSlotsComponent = ({
  category,
  image,
  name,
  ref_no,
  slotPrice,
  slotDate,
  slot_number,
  total_purchased_slot,
  shareLink,
  group_name,
}) => {
  const navigate = useNavigate();
  return (
    <div
      className={`min-w-80 w-full rounded-xl border-[1px] border-gray-200 p-4 hover:border-gray-300 ${
        category === "vegetable" && "bg-[#F9F9F9]"
      } ${category === "fruit" && "bg-[#D5EBBB]"} ${
        category === "meat" && "bg-[#EDE0C1]"
      }`}
    >
      <div className="flex items-center justify-between">
        <div className="flex h-6 w-[85px] items-center justify-center rounded-full bg-[#EDEEEC]">
          <p className="text-[11px] font-medium text-gray-500">
            {moment(slotDate).format("ll")}
          </p>
        </div>
        <div
          onClick={() => {
            navigator.clipboard.writeText(shareLink);
            toast.success("Copied to clipboard!");
          }}
          className="flex cursor-pointer flex-row items-center space-x-1 text-gray-700 hover:text-gray-500 "
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="currentColor"
              className="h-4 w-4"
            >
              <path
                fill-rule="evenodd"
                d="M15.75 4.5a3 3 0 1 1 .825 2.066l-8.421 4.679a3.002 3.002 0 0 1 0 1.51l8.421 4.679a3 3 0 1 1-.729 1.31l-8.421-4.678a3 3 0 1 1 0-4.132l8.421-4.679a3 3 0 0 1-.096-.755Z"
                clip-rule="evenodd"
              />
            </svg>
          </div>
          <span className="text-xs font-medium">Share</span>
        </div>
      </div>
      <div className="my-2 flex">
        <p className="text-xs font-medium text-gray-600">Group Name: </p>
        <p className="text-xs font-semibold text-gray-700">
          <span className="font-bold">{group_name}</span>
        </p>
      </div>
      <div
        onClick={() => navigate(`/gropool/detail/${ref_no}`)}
        className="mt-2 flex cursor-pointer flex-row space-x-2"
      >
        <div>
          <img
            src={image}
            alt="img"
            className="aspect-auto h-12 w-12 object-contain"
          />
        </div>
        <div>
          <p className="text-xs font-medium text-gray-600">
            {`${name} Sharing x${slot_number}`}
          </p>
          <p className="text-lg font-semibold text-gray-700">
            {/* <span className="text-clip align-text-top text-xs">N</span> */}
            <span className="font-bold">{CurrencyComponent(slotPrice)}</span>
            <span className="text-xs">/slot</span>
          </p>
        </div>
      </div>
      <div>
        <div className="mt-1 flex flex-row items-center justify-between text-xs font-normal text-gray-600">
          <p>Slots</p>
          <p>{`${total_purchased_slot}/${slot_number}`}</p>
        </div>
        <div className="mt-1">
          <ProgressBar
            height="8px"
            width="100%"
            bgColor={`${category === "fruit" ? "#558223" : "#45444B"}`}
            maxCompleted={slot_number}
            completed={total_purchased_slot}
            isLabelVisible={false}
            barContainerClassName={`rounded-full ${
              category === "vegetable" && "bg-[#EDEEEC]"
            } ${category === "fruit" && "bg-[#FFFFFF]"} ${
              category === "meat" && "bg-[#FFFFFF]"
            }`}
          />
        </div>
      </div>
    </div>
  );
};

export default AvailableSlotsComponent;
